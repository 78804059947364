import { useFetchInstance } from "~/api";
import { WishlistResponse } from "~/types/api/wishlist.types";
export const wishlistApi = {
  /**
   * Add to wishlist
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Wishlist-PostApiV1Wishlists
   * @param data
   * @returns {*}
   */
  addItem(data: any) {
    return useFetchInstance<WishlistResponse>("/wishlists", {
      method: "POST",
      body: data,
    });
  },
  /**
   * Get or create wishlist
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Wishlist-GetApiV1Wishlists
   * @returns {*}
   * @param uniqueId
   */
  getWishList(uniqueId?: string | null) {
    const params: { unique_id?: string } = {};
    if (uniqueId) {
      params.unique_id = uniqueId;
    }
    return useFetchInstance<WishlistResponse>(
      "/wishlists",
      { params },
      { skipErrors: [404, 500] }
    );
  },
};
